<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Histórico de vendas</h1>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="modal-cadastro-form pa-3 pb-1">
              <v-row class="align-end">
                <v-col cols="6" class="pa-3 pt-0 pb-0">
                  <rangeDate
                    :dtFinalProps.sync="dtFinal"
                    :dtInicialProps.sync="dtInicial"
                    :hasMonthOrDateProps.sync="hasMonthOrDate"
                    :inativosProps.sync="inativos"
                    :isDisplayMonthOrDateProps="true"
                  />
                </v-col>
                
                <v-col dense cols="6" class="pa-3 pt-0 pb-1" sm="6">
                  <autoCompleteEntities :entityProps.sync="entity" :typesEntities="['4']" :inativos="inativos"  :errorMessages="entityErrors"/>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="modal-cadastro-footer pt-0">
              <v-btn
                type="submit"
                :color="variables.colorPrimary"
                @click.prevent="submit"
                class="br-btn"
                :loading="loading">Processar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="box-table">
         <TabelaHistoricoVendas :data="transmissoes.data" :hasMonthOrDate="hasMonthOrDate" />
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>


  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'historicoVendas'

export default {
  name: 'Cadastro',
  components: {
    // BrToolBar: () => import('./components/toolbar'),
    autoCompleteEntities: () => import('../../components/autoCompleteEntities'),
    rangeDate: () => import('../../components/rangeDate'),
    TabelaHistoricoVendas: () => import('@/views/components/tabelaHistoricoVendas')
  },
  mixins: [listagemTables, validationMixin],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'Data mov.', value: 'date' },
      { align: 'start', class: 'table-header', text: 'Vendas', value: 'total' },
      { align: 'start', class: 'table-header', text: '(-) Comissão', value: 'commission' },
      { align: 'start', class: 'table-header', text: 'Líquido', value: 'liquid' },
      { align: 'start', class: 'table-header', text: 'Prêmios', value: 'prizes' },
      { align: 'start', class: 'table-header', text: 'Caixa', value: 'credit' },
      { align: 'start', class: 'table-header', text: 'PGTO', value: 'debit' }
    ],

    loading: false,
    loadingTable: false,
    entity: {},
    entityRequest: {},
    dtFinal: '',
    dtInicial: '',
    inativos: null,
    hasMonthOrDate: 1
  }),

  watch: {
    hasMonthOrDate (val) {
      this.setHasMonthOrDate(val)
    },

    transmissoes (val) {
      this.entityRequest = val.entity
    }
  },

  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'transmissoes']),

    entityErrors () {
      const errors = []
      if (!this.$v.entity.$dirty) return errors
      !this.$v.entity.required && errors.push('Favor selecionar a revenda!')
      return errors
    },
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'getFilterVendas', 'setHasMonthOrDate']),

    init() {},

    submit () {
      if (this.$v.entity.$invalid) { 
        this.$v.entity.$touch()
        return false 
      }

      this.loading = true
      // eslint-disable-next-line
      let { dtInicial, dtFinal, entity, inativos } = this
      
      if (this.hasMonthOrDate === 2) {
        dtInicial = moment(dtInicial, 'YYYY-MM').startOf('month').format('YYYY-MM-DD')
        dtFinal = moment(dtFinal, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
      }

      this.getFilterVendas({ dtInicial, dtFinal, inativos, entityId: entity.value }).then(() => {
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    }
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::pgto')
    Events.$off('modal::historicoVendas::caixa')
    Events.$off('modal::historicoVendas::jogosWinners')
    Events.$off('modal::historicoVendas::transmissoes')
  },

  validations () {
    return  {
      entity: { required }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
